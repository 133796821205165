const $ = require('jquery');
global.jQuery = $;
global.$ = $; // Remove this..
const bs = require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap.js');
const matchHeight = require('jquery-match-height');
const magnificPopup = require('magnific-popup');
const tablesorter = require('tablesorter');

$(function($) {
	if ( $( ".eqHeight" ).length > 0 ) {
		$('.eqHeight').matchHeight();
	}
	if ( $( ".rb-category-title" ).length > 0 ) {
		$('.rb-category-title').matchHeight();
	}
	if ( $( ".rb-productcategory" ).length > 0 ) {
		$('.rb-productcategory').matchHeight();
	}
});

$(document).ready( function() {
	/* Tablesort */
	$('.rb-table-sortable').map((index, element) => {
		$(element).tablesorter({
			cssChildRow: "tablesorter-childRow"
		});
	});

	/* Toggle hamburger menu */
	if ( ( ".rb-xs-menu-toggle" ).length > 0 ) {
		$( ".rb-xs-menu-toggle" ).on( 'click', function(e){
			e.preventDefault();
			console.log( 'click' );
			$( ".rb-menu-xs" ).slideToggle( 'slow' );
		} );
	}

	if ( $( ".rb-article" ).length > 0 ) {
		if( $( ".rb-article table" ).length > 0 ) {
			var $table = $( ".rb-article table" );
			//table table-condensed table-striped
			if ( !$table.hasClass( 'table' ) ) {
				$table.addClass( 'table' );
			}
			if ( !$table.hasClass( 'table-condensed' ) ) {
				$table.addClass( 'table-condensed' );
			}
			if ( !$table.hasClass( 'table-striped' ) ) {
				$table.addClass( 'table-striped' );
			}
		}
	}

	// Image gallery
	if($(".rb-image-gallery").length > 0) {
		$('.rb-image-gallery').each(function() {
			$(this).magnificPopup({
				delegate: 'a',
				type: 'image',
				tLoading: 'Laster bilde',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0,1] // Will preload 0 - before current, and 1 after the current image
				},
				image: {
					tError: '<a href="%url%">Kunne ikke laste bilde #%curr%</a>.',
					titleSrc: function(item) {
						return item.el.attr('title');
					}
				}
			});
		});
	}



  if ($('.accordion-body').length > 0) {

	$('.accordion-body').on('show.bs.collapse', function () {
		var prodid = $(this).attr('data-attr-id');
		getDocs(prodid, $(this));
	});

	let printType = function (mimetype) {
		switch(mimetype) {
			case 'image/vnd.dwg':
				return 'AutoCAD';
			case 'application/pdf':
				return 'PDF';
			case 'image/jpeg':
				return 'JPG';
			case 'image/png':
				return 'PNG';
			case 'application/x-tika-msoffice':
				return 'Autodesk Inventor';
			default:
				return mimetype;
		}
	};

	let isImage = function (filename) {
		 var exts = ['jpg', 'peg', 'png', 'gif'];
		 return ($.inArray(filename.slice(-3).toLowerCase(), exts) > -1 ? true : false);
	};

	let docRow = function (file) {
		return $('<tr><td><a target="_blank" href="/api/doc/'+file.filename+'"><span class="glyphicon glyphicon-download-alt"></span>&nbsp;&nbsp;'+file.displayname +
                         '</a></td><td>'+printType(file["mime-type"])+'</td><td class="text-right">'+file.size + '</td></tr>');
	};

	let imgThumb = function (file) {
		 return $('<div class="col-xs-6 col-md-3"><a href="/api/doc/'+file.filename+'" target="_blank"><img src="/api/doc/thumbnail/'+file.filename+'" width="100%"></a></div>');
	};

	let getDocs = function (prodid, element) {
		$.ajax({
			url: '/api/docs/'+prodid
		}).done(function (response) {

			var target = element.find('div.documents'),
			    wrapper = $('<div class="row"></div>'),
			    tablewrapper = $('<div class="col-xs-12 col-md-6"></div>'),
			    imagewrapper = $('<div class="col-xs-12 col-md-6"><div class="row"></div></div>'),
			    table = $('<table class="table table-condensed borderless table-responsive">' +
					    '<thead>' +
						    '<tr><td><strong>Filnavn</strong></td><td><strong>Filtype</strong></td><td><strong>St&oslash;rrelse</strong></td></tr>' +
					    '</thead>' +
					    '</table>'),
			    tbodydocs = $('<tbody class="docs"></tbody>');

			target.empty();

			if (response.files.length === 0) {
				target.append($('<p>Fant ingen dokumenter</p>'));
			} else {
				$.each(response.files, function (i, file) {
					if (isImage(file.filename)) {
						imagewrapper.find('div.row').append(imgThumb(file));
					} else {
						tbodydocs.append(docRow(file));
					}
				});
				tablewrapper.prepend($('<h5>Dokumenter</h5>'));
				imagewrapper.prepend($('<h5>Bilder</h5>'));
				wrapper.append(tablewrapper.append(table.append(tbodydocs)))
				       .append(imagewrapper);
				target.append(wrapper);
			}
		});
	};

	$('.collapse').on('show.bs.collapse', function () {
		$('.collapse.in').collapse('hide');
	});

  }

} );
